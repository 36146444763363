import * as ko from 'knockout';
import ClipboardJS from 'clipboard';
import i18next from 'i18next';
import { displayMessage } from '../Services/MessageService';
import { MessageType } from '../Services/Enums/MessageType';

ko.bindingHandlers.clipboard = {
    init: function (element) {
        const clipboard = new ClipboardJS(element);

        clipboard.on('success', function () {
            displayMessage(
                i18next.t('shared:ClipboardSuccess'),
                null,
                MessageType.SUCCESS);
        });

        clipboard.on('error', function () {
            displayMessage(
                i18next.t('shared:ClipboardError'),
                null,
                MessageType.ERROR);
        });
    }
};
