import * as ko from 'knockout';

const animations = [
    'bounce', 'flash', 'pulse', 'rubberBand', 'shake', 'swing', 'tada', 'wobble', 'bounceIn', 'bounceInDown',
    'bounceInLeft', 'bounceInRight', 'bounceInUp', 'bounceOut', 'bounceOutDown', 'bounceOutLeft', 'bounceOutRight',
    'bounceOutUp', 'fadeIn', 'fadeInDown', 'fadeInDownBig', 'fadeInLeft', 'fadeInLeftBig', 'fadeInRight',
    'fadeInRightBig', 'fadeInUp', 'fadeInUpBig', 'fadeOut', 'fadeOutDown', 'fadeOutDownBig', 'fadeOutLeft',
    'fadeOutLeftBig', 'fadeOutRight', 'fadeOutRightBig', 'fadeOutUp', 'fadeOutUpBig', 'flip', 'flipInX', 'flipInY',
    'flipOutX', 'flipOutY', 'lightSpeedIn', 'lightSpeedOut', 'rotateIn', 'rotateInDownLeft', 'rotateInDownRight',
    'rotateInUpLeft', 'rotateInUpRight', 'rotateOut', 'rotateOutDownLeft', 'rotateOutDownRight', 'rotateOutUpLeft',
    'rotateOutUpRight', 'hinge', 'rollIn', 'rollOut', 'zoomIn', 'zoomInDown', 'zoomInLeft', 'zoomInRight', 'zoomInUp',
    'zoomOut', 'zoomOutDown', 'zoomOutLeft', 'zoomOutRight', 'zoomOutUp'
];
const baseAnimateClass = 'animated';

function hasClass(ele, cls) {
    return ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

function addClass(ele, cls) {
    if (!hasClass(ele, cls)) {
        ele.className = ele.className ? ele.className + ' ' + cls : cls;
    }
}

function removeClass(ele, cls) {
    if (hasClass(ele, cls)) {
        const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
        ele.className = ele.className.replace(reg, ' ').trim();
    }
}

function doAnimationWork(element, animation, callback, state) {
    addClass(element, baseAnimateClass);
    addClass(element, animation);

    function animationEndCallback(event) {
        element.removeEventListener('animationend', animationEndCallback);

        removeClass(element, baseAnimateClass);
        removeClass(element, animation);

        if (typeof callback === 'function') {
            callback(event, state);
        }
    }

    element.addEventListener('animationend', animationEndCallback, false);
}

ko.bindingHandlers.animate = {
    init: function (element, valueAccessor) {
        const data = ko.unwrap(valueAccessor());

        if (!data.animation) {
            throw new Error('Animation property must be defined');
        }

        const animation = ko.unwrap(data.animation);
        const animationOn = typeof animation === 'object' ? animation[0] : animation;
        const animationOff = typeof animation === 'object' ? animation[1] : animation;

        if (animationOn && animations.indexOf(animationOn) === -1) {
            throw new Error('Invalid first animation');
        }

        if (animationOff && animations.indexOf(animationOff) === -1) {
            throw new Error('Invalid second animation');
        }
    },
    update: function (element, valueAccessor) {
        const data = ko.unwrap(valueAccessor());

        if (!data.animation) {
            throw new Error('Animation property must be defined');
        }

        const animation = ko.unwrap(data.animation);
        const state = !!ko.unwrap(data.state);
        const animationOn = typeof animation === 'object' ? animation[0] : animation;
        const animationOff = typeof animation === 'object' ? animation[1] : animation;
        const toggle = animationOn !== animationOff;
        const handler = ko.unwrap(data.handler) || undefined;

        if (state) {
            doAnimationWork(element, animationOn, handler, true);
        } else if (toggle) {
            doAnimationWork(element, animationOff, handler, false);
        }
    }
};
