import $ from 'jquery';
import 'jquery.complexify/jquery.complexify.banlist';
import 'jquery.complexify';
import 'bootstrap-social/bootstrap-social.css';
import { PageLoaderService } from '../../Services/PageLoaderService';
import { displayMessage } from '../../Services/MessageService';
import { MessageType } from '../../Services/Enums/MessageType';

function InitPasswordMeter() {
    $.seatingplan(document).pageReady(function () {
        $('#Password, #NewPassword').complexify({
            strengthScaleFactor: 0.5
        }, function (valid, complexity) {
            if (!valid) {
                $('#progress').css({ 'width': complexity + '%' }).removeClass('progressbarValid').addClass('progressbarInvalid');
            } else {
                $('#progress').css({ 'width': complexity + '%' }).removeClass('progressbarInvalid').addClass('progressbarValid');
            }
            $('#complexity').html(Math.round(complexity) + '%');
        });

        PageLoaderService.update(100);

        $('.pro-type').show();
    });
}

function SetProType(val) {
    $('input[name="proType"]').val(val);
    $('.register').show();
    $('.pro-type').hide();
}

window.SetProType = SetProType;
window.InitPasswordMeter = InitPasswordMeter;

function InitAccountIndex(data) {
    $.seatingplan(document).pageReady(function () {
        PageLoaderService.update(100);

        if (data && data.error) {
            displayMessage(
                data.error,
                null,
                MessageType.ERROR);
        }

        if ($('#proType').val() === '') {
            $('.pro-type').show();
            $('.register').hide();
        }
        else {
            $('.register').show();
            $('.pro-type').hide();
        }
    });

    
}

window.InitAccountIndex = InitAccountIndex;
