import * as ko from 'knockout';
import $ from 'jquery';

ko.bindingHandlers.sizeRatio = {
    init: function (element, valueAccessor) {
        const elt = $(element);

        function setHeight() {
            const width = elt.width();
            elt.height(width * ko.unwrap(valueAccessor()));
        }

        setHeight();
        $(window).resize(function () {
            setHeight();
        });
    }
};
