import * as ko from 'knockout';
import $ from 'jquery';
import Controller from './Controller';
import { PageLoaderService } from '../../Services/PageLoaderService';

function InitProDomainIndex(config) {
    $.seatingplan(document).pageReady(() => {
        new Controller(config).create();
    });
}

window.InitProDomainIndex = InitProDomainIndex;

function InitProDomainOffers() {
    $.seatingplan(document).pageReady(() => {
        const vm = new function () {
            const self = this;
            self.isTermsAccepted1 = ko.observable(false);
            self.isTermsAccepted2 = ko.observable(false);
        };
        ko.applyBindings(vm);
        PageLoaderService.update(100);
    });
}

window.InitProDomainOffers = InitProDomainOffers;
