import $ from 'jquery';
import * as ko from 'knockout';
import youTubePlayer from 'youtube-player';

function viewModel() {
    const demoPlayer = youTubePlayer('demo-video-player', {
        playerVars: {
            listType: 'playlist',
            list: 'PLtbaAlAdFsONxmcCQRCBwrYYKaPDI_2z-',
            rel: 0,
            index: getVideoIndexByCurrentLanguage(),
        },
    });

    const vm = this;
    vm.data = null;
    vm.showDemoVideoModal = ko.observable(false);
    vm.scrollTo = scrollTo;

    vm.carouselPrev = carouselPrev;
    vm.carouselNext = carouselNext;

    init();

    function init() {
        vm.showDemoVideoModal.subscribe(() => {
            if (vm.showDemoVideoModal()) {
                demoPlayer.playVideo();
            } else {
                demoPlayer.stopVideo();
            }
        });
    }

    function getVideoIndexByCurrentLanguage() {
        const language = window.language || 'en';

        switch (language.toLowerCase()) {
            case 'en':
                return 2;
            case 'es':
                return 1;
            default:
                return 0;
        }
    }

    function scrollTo(element) {
        const $element = $(element);

        $('html, body').animate({
            scrollTop: $element.length ? $element.offset().top : 0,
        }, 500);
    }

    function carouselPrev(carouselSelector) {
        const carousel = $(carouselSelector);
        const carouselItemContainer = carousel.find('ul');
        const seats = carousel.find('li');
        const el = carousel.find('.is-ref').removeClass('is-ref');
        let newSeat = getPreviousCarouselElement(el, seats);
        carouselItemContainer.addClass('is-reversing');

        newSeat.addClass('is-ref').css('order', 1);

        for (let i = 2; i <= seats.length; i++) {
            newSeat = getNextCarouselElement(newSeat, seats).css('order', i);
        }

        carouselItemContainer.removeClass('is-set');
        setTimeout(() => carouselItemContainer.addClass('is-set'), 50);
    }

    function carouselNext(carouselSelector) {
        const carousel = $(carouselSelector);
        const carouselItemContainer = carousel.find('ul');
        const seats = carousel.find('li');
        const el = carousel.find('.is-ref').removeClass('is-ref');
        let newSeat = getNextCarouselElement(el, seats);
        carouselItemContainer.removeClass('is-reversing');

        newSeat.addClass('is-ref').css('order', 1);

        for (let i = 2; i <= seats.length; i++) {
            newSeat = getNextCarouselElement(newSeat, seats).css('order', i);
        }

        carouselItemContainer.removeClass('is-set');
        setTimeout(() => carouselItemContainer.addClass('is-set'), 50);
    }

    function getPreviousCarouselElement(element, seats) {
        if (element.prev().length > 0) {
            return element.prev();
        }
        return seats.last();
    }

    function getNextCarouselElement(element, seats) {
        if (element.next().length > 0) {
            return element.next();
        }
        return seats.first();
    }
}

export default viewModel;