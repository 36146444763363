import * as ko from 'knockout';
import imageService from '../../../Services/ImageService';

export default class PictureModel {
    constructor(data, mapping) {
        this.mapping = mapping;
        this.data = {};
        this.initialValues = data;
        this.showLoader = ko.observable(false);
        this.$type = 'PictureModel';

        this.progress = ko.observable(0);

        this.init(data);
    }

    revertChanges() {
        ko.mapping.fromJS(this.initialValues, this.mapping, this.data);
    }

    saveChanges() {
        this.initialValues = ko.mapping.toJS(this.data);
    }

    changePicture(file, onLoadedCallback) {
        if (!file.type || file.type.indexOf('image') === 0) {
            this.progress(0);
            this.showLoader(true);

            imageService
                .scaleImage(file, 1280)
                .then((scaledImage) => {
                    this.showLoader(false);
                    this.lightFileUrl(scaledImage);

                    if (onLoadedCallback) {
                        onLoadedCallback();
                    }
                });
        }
    }

    init(data) {
        ko.mapping.fromJS(data, this.mapping, this);
        if (data.lightFileUrl && data.lightFileUrl.indexOf('base64') < 0) {
            this.progress(100);
        } else {
            this.progress(0);
        }
    }
}
