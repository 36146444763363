import $ from 'jquery';
import { PageLoaderService } from '../../Services/PageLoaderService';

function InitHomeServicesPricing() {
    $.seatingplan(document).pageReady(function () {
        PageLoaderService.update(100);
    });
}

window.InitHomeServicesPricing = InitHomeServicesPricing;
