import i18next from 'i18next';
import { ErrorService } from './ErrorService';
import { displayMessage } from './MessageService';
import { MessageType } from './Enums/MessageType';
import { Workbox } from 'workbox-window';

declare global {
    interface Window {
        environment: string;
    }
}

export class ServiceWorkerService {
    public static register() {
        if (window.environment === 'DEVELOPMENT') {
            return ServiceWorkerService.unregisterAll();
        }

        if ('serviceWorker' in navigator) {
            const wb = new Workbox('/service-worker.js');

            wb.addEventListener('message', (event) => {
                if (event.data.type === 'CACHE_UPDATED') {
                    displayMessage(
                        i18next.t('shared:NewVersionAvailable'),
                        null,
                        MessageType.WARNING,
                        true);
                }
            });

            wb.addEventListener('redundant', () => {
                console.error('The installing service worker became redundant.');
                ServiceWorkerService.unregisterAll();
            });

            return wb.register()
                .catch((ex) => {
                    ErrorService.manageError(
                        {
                            name: 'Error during service worker getRegistration',
                        },
                        ex,
                        ex ? ex.stack : null);
                    throw ex;
                });
        }
        else {
            return Promise.resolve();
        }
    }

    public static update() {
        return new Promise((resolve, reject) => {
            try {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.getRegistration('/service-worker.js').then((reg) => {
                        if (reg) {
                            reg.update();
                        } else {
                            ServiceWorkerService.register();
                        }
                        resolve();
                    }).catch((ex) => {
                        ErrorService.manageError(
                            {
                                name: 'Error during service worker getRegistration',
                            },
                            ex,
                            ex ? ex.stack : null);
                        reject(ex);
                    });
                }
            } catch (ex) {
                ErrorService.manageError(
                    {
                        name: 'Error during service worker getRegistration',
                    },
                    ex,
                    ex ? ex.stack : null);
                reject(ex);
            }
        });
    }

    public static unregisterAll() {
        return new Promise((resolve, reject) => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistrations().then((registrations) => {
                    if (registrations) {
                        const unregisterPromises = registrations.map(reg => reg.unregister());
                        Promise.all(unregisterPromises).then(resolve);
                    } else {
                        resolve();
                    }
                }).catch((ex) => {
                    ErrorService.manageError(
                        {
                            name: 'Error during service worker getRegistrations',
                        },
                        ex,
                        ex ? ex.stack : null);
                    reject(ex);
                });
            }
        });
    }
}
