import $ from 'jquery';
import { PageLoaderService } from '../../Services/PageLoaderService';

function InitHomePartners() {
    $.seatingplan(document).pageReady(function () {
        PageLoaderService.update(100);
    });
}

window.InitHomePartners = InitHomePartners;
