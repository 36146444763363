import { memoize } from 'lodash-es';
import { Browser } from './Enums/Browser';

export class BrowserInfoService {
    private memoizedGetBrowserInfo = memoize(this.collectBrowserInfo);

    public getBrowserInfo() {
        return this.memoizedGetBrowserInfo();
     }

    private collectBrowserInfo() {
        const usrAgt = navigator.userAgent;
        let browserName = navigator.appName;
        let fullVersion = `${parseFloat(navigator.appVersion)}`;
        let majorVersion = parseInt(navigator.appVersion, 10);
        let offsetName;
        let offsetVer;
        let verIndex;

        if ((offsetVer = usrAgt.indexOf('Opera')) !== -1) {
            // detect Opera
            browserName = Browser.Opera;
            fullVersion = usrAgt.substring(offsetVer + 6);
            if ((offsetVer = usrAgt.indexOf('Version')) !== -1) {
                fullVersion = usrAgt.substring(offsetVer + 8);
            }
        } else if ((offsetVer = usrAgt.indexOf('MSIE')) !== -1) {
            // detect MSIE
            browserName = Browser.MicrosoftInternetExplorer;
            fullVersion = usrAgt.substring(offsetVer + 5);
        } else if ((offsetVer = usrAgt.indexOf('.NET CLR')) !== -1) {
            // detect INTERNET EXPLORER
            browserName = Browser.MicrosoftInternetExplorer;
        } else if ((offsetVer = usrAgt.indexOf('Edge')) !== -1) {
            // detect Edge
            browserName = Browser.MicrosoftEdge;
            fullVersion = usrAgt.substring(offsetVer + 5);
        } else if ((offsetVer = usrAgt.indexOf('Chrome')) !== -1) {
            // detect Chrome
            browserName = Browser.Chrome;
            fullVersion = usrAgt.substring(offsetVer + 7);
        } else if ((offsetVer = usrAgt.indexOf('Safari')) !== -1) {
            // detect Safari
            browserName = Browser.Safari;
            fullVersion = usrAgt.substring(offsetVer + 7);
            if ((offsetVer = usrAgt.indexOf('Version')) !== -1) {
                fullVersion = usrAgt.substring(offsetVer + 8);
            }
        } else if ((offsetVer = usrAgt.indexOf('Firefox')) !== -1) {
            // detect Firefox
            browserName = Browser.Firefox;
            fullVersion = usrAgt.substring(offsetVer + 8);
        } else if ((offsetName = usrAgt.lastIndexOf(' ') + 1) <
            (offsetVer = usrAgt.lastIndexOf('/'))) {
            // detect other browsers
            browserName = usrAgt.substring(offsetName, offsetVer);
            fullVersion = usrAgt.substring(offsetVer + 1);
            if (browserName.toLowerCase() === browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }

        // trim the fullVersion string at semicolon or space (if present)
        if ((verIndex = fullVersion.indexOf(';')) !== -1) {
            fullVersion = fullVersion.substring(0, verIndex);
        }
        if ((verIndex = fullVersion.indexOf(' ')) !== -1) {
            fullVersion = fullVersion.substring(0, verIndex);
        }
        majorVersion = parseInt(`${fullVersion}`, 10);
        if (isNaN(majorVersion)) {
            fullVersion = `${parseFloat(navigator.appVersion)}`;
            majorVersion = parseInt(navigator.appVersion, 10);
        }

        return {
            browserName,
            fullVersion,
            majorVersion,
            browserEngineName: navigator.product,
            browserApplicationName: navigator.appName,
            browserApplicationCodeName: navigator.appCodeName,
            browserApplicationVersion: navigator.appVersion,
            browserUserAgent: navigator.userAgent,
            browserPlatform: navigator.platform,
            browserLanguage: navigator.language,
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
            javaEnabled: typeof navigator.javaEnabled === 'function'
                && navigator.javaEnabled() ? navigator.javaEnabled() : false,
        };
    }
}
