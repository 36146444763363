import $ from 'jquery';
import {
    find,
    map,
} from 'lodash-es';
import i18next from 'i18next';
import moment from 'moment';
import 'blueimp-file-upload';
import {
    ErrorService,
} from '../Services/ErrorService';
import {
    displayMessage,
} from '../Services/MessageService';
import {
    MessageType,
} from '../Services/Enums/MessageType';
import 'tempusdominus-bootstrap-4';
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css';

class SeatingPlanCore extends $.fn.init {
    constructor(selector) {
        super($(selector));
    }

    pageReady(fn) {
        $(document).ready(fn);
        return this;
    }

    bindJquery() {
        this.initRequiredFieldsLabel();
        this.initDateTimes();
        this.initOffline();

        $('.fileupload').fileupload({
            dataType: 'json',
            autoUpload: false,
            singleFileUploads: false,
            change: (_e, data) => {
                if (data.files && data.files.length > 0) {
                    const textInput = $(_e.target).find('.filename');
                    const files = map(data.files, 'name');
                    textInput.val(`"${files.join('" "')}"`);

                    const uploadButton = $(_e.target).parents('.modal').find('.upload');
                    uploadButton.unbind();
                }
            },
            add: (_e, data) => {
                const uploadButton = $(_e.target).parents('.modal').find('.upload');
                uploadButton.bind('click', () => {
                    uploadButton.unbind();
                    data.submit();
                });
            },
            done: (_e, data) => {
                const fileupload = $(_e.target);
                const modal = fileupload.parents('.modal');
                const progressBar = fileupload.find('.progress .progress-bar');

                setTimeout(() => {
                    fileupload.trigger('reset');
                    progressBar.css('width', '0');
                    progressBar.find('span').text('0%');

                    if (data.result.length > 0) {
                        let isSuccess = true;
                        for (let i = 0; i < data.result.length; i++) {
                            const result = data.result[i];
                            if (result.successMessage) {
                                const title = modal.find('.modal-title').text();
                                displayMessage(
                                    result.successMessage,
                                    title,
                                    MessageType.SUCCESS,
                                );
                                fileupload.trigger('fileuploadsuccess');
                            }

                            if (result.errorMessage) {
                                isSuccess = false;
                                displayMessage(
                                    result.errorMessage,
                                    i18next.t('shared:Error'),
                                    MessageType.ERROR,
                                );
                            }
                        }

                        if (isSuccess) {
                            modal.modal('hide');
                        }
                    }
                }, 1500);
            },
        }).on('fileuploadprogress', (_e, data) => {
            const fileupload = $(_e.target);
            const progress = parseInt(data.loaded / data.total * 100, 10);
            const progressBar = fileupload.find('.progress .progress-bar');
            progressBar.css('width', `${progress}%`);
            progressBar.find('span').text(`${progress}%`);
        }).on('fileuploadsubmit', (_e, data) => {
            data.formData = data.form.find(':input').serializeArray();
        });
    }

    getDecimalSeparator() {
        let currentSeparator;
        const n = 1.1;
        currentSeparator = n.toLocaleString().substring(1, 2);
        return currentSeparator;
    }

    getServerDecimalSeparator() {
        return (1.1).toLocaleString(i18next.language).substring(1, 2);
    }

    initRequiredFieldsLabel() {
        return this.each((_, rootElt) => {
            $(rootElt)
                .find('*[data-val-required]:not(:checkbox, :radio)')
                .each((_index, requiredElt) => {
                    let parent = $(requiredElt).parent();
                    let parentIndex = 0;
                    const id = $(requiredElt).attr('id').replace('.', '_');
                    const name = $(requiredElt).attr('name').replace('_', '.');
                    while (parentIndex < 3 && parent && parent.find(`label[for="${id}"]`).length === 0 && parent.find(`label[for="${name}"]`).length === 0) {
                        parent = parent.parent();
                        parentIndex++;
                    }

                    const label = parent.find(`label[for="${id}"], label[for="${name}"]`);
                    const text = label.text();
                    if (text.length > 0 && label.find('span.required-mark').length === 0) {
                        label.append('<span class="required-mark">*</span>');
                    }
                });
        });
    }

    initDateTimes() {
        return this.each((_, rootElt) => {
            const currentLang = i18next.language;

            function initializedInput(inputElt) {
                const input = $(inputElt);
                if (!input.data('initialized')) {
                    const elt = getPickerElement(inputElt);
                    elt.datetimepicker('destroy');

                    const addon = getPickerAddonElement(inputElt);
                    addon.data('target', null);

                    input.data('target', null);
                    input.val(input.attr('data-val-initial'));
                    input.data('initialized', true);
                }
            }

            function getPickerElement(inputElt) {
                const elt = $(inputElt);
                const inputGroup = elt.parent('.input-group');
                return inputGroup.length ? $(inputGroup[0]) : elt;
            }

            function getPickerAddonElement(inputElt) {
                const elt = $(inputElt);
                const inputGroup = elt.parent('.input-group');
                return inputGroup.length ? $(inputGroup[0]).find('.input-group-append') : elt;
            }

            $(rootElt)
                .find('input[type="date"]')
                .each((_index, dateElt) => {
                    $(dateElt).parents('.input-group.date').find('.input-group-append').hide();
                    initializedInput(dateElt);
                });

            $(rootElt)
                .find('input[type="datetime"]')
                .each((_index, dateElt) => {
                    $(dateElt).parents('.input-group.date').find('.input-group-append').hide();
                    initializedInput(dateElt);
                });


            $(rootElt)
                .find('input[type="time"]')
                .each((_index, dateElt) => {
                    $(dateElt).parents('.input-group.date').find('.input-group-append').hide();
                    initializedInput(dateElt);
                });
        });
    }

    initOffline() {
        window.Offline.on('confirmed-down', () => {
            $('.online').fadeOut(() => {
                $('.offline').fadeIn();
            });
        });

        window.Offline.on('confirmed-up', () => {
            $('.offline').fadeOut(() => {
                $('.online').fadeIn();
            });
        });
    }
}

const seatingplan = selector => new SeatingPlanCore(selector);
$.seatingplan = seatingplan;

seatingplan.bindUnobtrusiveValidation = (elements) => {
    if (elements && elements.length) {
        let form = find(elements, elt => elt.tagName && elt.tagName.toLowerCase() === 'form');

        if (form) {
            $.validator.unobtrusive.parse(form);
            $.seatingplan(form).bindJquery();
        } else {
            form = $(elements[0]).parents('form');
            if (form.length) {
                $.validator.unobtrusive.parse(form[0]);
                $.seatingplan(form[0]).bindJquery();
            } else {
                $.validator.unobtrusive.parse(elements);
                $.seatingplan(elements).bindJquery();
            }
        }
    }
};

seatingplan.validateHandler = (callback, ...args) => {
    let event;
    let returnValue = false;
    for (let i = 0; i < args.length; i++) {
        if (args[i] && args[i].target) {
            event = args[i];
            break;
        }
    }

    if (event) {
        const target = $(event.target);
        let form = target.parents('form');
        if (!form.length) {
            form = $('#main-content form');
        }
        const result = form.valid();

        if (result) {
            if (callback) {
                returnValue = callback.apply(callback, args);
            } else {
                returnValue = true;
            }
        }

        if (!returnValue) {
            if (event.preventDefault) {
                event.preventDefault();
            } else {
                event.returnValue = false;
            }

            event.cancelBubble = true;
            if (event.stopPropagation) {
                event.stopPropagation();
            }
        }
    }

    return returnValue;
};

seatingplan.isConnectionUp = () => window.Offline.state === 'up';

window.onerror = (msg, file, line, col, exception) => {
    const error = new Error(msg);
    error.fileName = file;
    error.lineNumber = line;
    error.columnNumber = col;
    ErrorService.manageError(error, exception, exception ? exception.stack : null);
};

$.Deferred.exceptionHook = (exception, stack) => {
    ErrorService.manageError({}, exception, exception.stack || stack);
};
