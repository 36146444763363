import {
    Api,
} from '../../Services/Api';

export default class Repository {
    constructor(configuration) {
        this.configuration = configuration;
    }

    getCaterer() {
        return Api.Caterer({
            showAjaxLoader: true,
            updatePageLoader: true,
        }).loadCaterer(this.configuration.id);
    }

    saveInfo(name, story, languages, isSearchEngineIndexed) {
        return Api.Caterer({
            showAjaxLoader: true,
            ajaxLoader: '.caterer-info > .AjaxLoader',
        }).updateInfo(this.configuration.id, {
            name,
            story,
            languages,
            isSearchEngineIndexed,
        });
    }

    deletePicture(id) {
        return Api.Caterer({
            showAjaxLoader: false,
        }).deletePicture(id);
    }


    saveContact(address, phoneNumbers, emails, website) {
        return Api.Caterer({
            showAjaxLoader: true,
            ajaxLoader: '.caterer-contact > .AjaxLoader',
        }).updateContact(this.configuration.id, {
            address,
            phoneNumbers,
            emails,
            website,
        });
    }

    saveServices(services) {
        return Api.Caterer({
            showAjaxLoader: true,
            ajaxLoader: '.caterer-services > .AjaxLoader',
        }).updateServices(this.configuration.id, services);
    }

}