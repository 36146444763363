import * as ko from 'knockout';
import {
    template,
} from 'lodash-es';
import ProDomainModel from './ProDomainModel';
import ProDomainRoomModel from './ProDomainRoomModel';
import PictureModel from './../../Shared/Picture/PictureModel';

export default class ViewModel {
    constructor(repository, options, proDomain) {
        this.repository = repository;
        this.options = options;
        this.mapping = {
            rooms: {
                create: configuration => new ProDomainRoomModel(configuration.data, this.mapping, this.repository, this.options),
            },
            pictures: {
                create: configuration => new PictureModel(configuration.data, this.mapping),
            },
        };

        this.data = {
            proDomain: null,
        };
        this.currentRoomPlanId = ko.observable();
        this.isTermsAccepted = ko.observable(false);

        this.viewPageOnSeatingPlanUrlTemplate = template(this.options.viewPageOnSeatingPlanUrl);
        this.viewPageOnSeatingPlanUrl = ko.pureComputed(() => {
            let viewPageOnSeatingPlanUrl = null;

            if (this.data.proDomain && this.data.proDomain.data.id()) {
                viewPageOnSeatingPlanUrl = this.viewPageOnSeatingPlanUrlTemplate({
                    id: this.data.proDomain.data.id(),
                });
            }

            return viewPageOnSeatingPlanUrl;
        });

        this.init(proDomain);
    }

    plainTextToHtml(text) {
        if (text !== undefined && text !== null) {
            return text.replace(/\r\n/g, '<br />').replace(/\n/g, '<br />').replace(/\r/g, '<br />');
        }
        return text;
    }

    init(proDomain) {
        if (this.data.proDomain) {
            this.data.proDomain.init(proDomain);
        } else {
            this.data.proDomain = new ProDomainModel(proDomain, this.mapping, this.repository, this.options);
        }
    }
}