import { assign } from 'lodash-es';
import * as ko from 'knockout';
import $ from 'jquery';
import moment from 'moment';
import filesize from 'file-size';

ko.bindingHandlers.fileUpload = {
    init: function (element, valueAccessor, allBindings) {
        const valueUnwrapped = ko.unwrap(valueAccessor());
        const bindings = ko.unwrap(allBindings());
        const elt = $(element);

        const defaultOptions = {
            dataType: 'json',
            autoUpload: true,
            singleFileUploads: true,
            limitConcurrentUploads: 2,
            maxFileSize: 2000000,
            done: valueUnwrapped
        };

        const options = assign({}, defaultOptions, bindings);

        options.progress = function (e, data) {
            data.progress = Math.floor(data.loaded / data.total * 100);
            if (bindings.progress) {
                bindings.progress(e, data);
            }
        };

        options.progressall = function (e, data) {
            data.progress = Math.floor(data.loaded / data.total * 100);
            data.extendedProgress = computeExtendedProgress(data);
            if (bindings.progressall) {
                bindings.progressall(e, data);
            }
        };

        elt.fileupload(options);
    }
};

function formatBitrate(bits) {
    if (typeof bits !== 'number') {
        return '';
    }
    if (bits >= 1000000000) {
        return (bits / 1000000000).toFixed(2) + ' Gbit/s';
    }
    if (bits >= 1000000) {
        return (bits / 1000000).toFixed(2) + ' Mbit/s';
    }
    if (bits >= 1000) {
        return (bits / 1000).toFixed(2) + ' kbit/s';
    }
    return bits.toFixed(2) + ' bit/s';
}

function formatPercentage(floatValue) {
    return (floatValue * 100).toFixed(2) + ' %';
}

function computeExtendedProgress(data) {
    const remainingTime = (data.total - data.loaded) * 8 / data.bitrate;

    return formatBitrate(data.bitrate) + ' | ' +
        moment.duration(remainingTime).humanize() + ' | ' +
        formatPercentage(data.loaded / data.total) + ' | ' +
        filesize(data.loaded).human() + ' / ' + filesize(data.total).human();
}
