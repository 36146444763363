import $ from 'jquery';
import * as ko from 'knockout';
import 'css-toggle-switch/dist/toggle-switch.css';

ko.bindingHandlers.toggleSwitch = {
    init: function (element, valueAccessor, allBindings) {
        const $elem = $(element);
        const data = $elem.data();

        const $wrapper = $('<label class="switch-light"></label>');
        if (data.size) {
            $wrapper.addClass(data.size);
        }
        
        $elem.wrap($wrapper);
        const $switchElt = $('<span class="card"></span>');
        let elt = '<span>' + (data.offText ? data.offText : 'Off') + '</span><span>' + (data.onText ? data.onText : 'On') + '</span>';
        elt += '<a class="btn ' + (data.onColor ? 'btn-' + data.onColor : 'btn btn-pink') + '"></a>';
        $switchElt.append(elt);
        $elem.after($switchElt);

        if (data.label) {
            const $label = $('<strong>' + data.label + '</strong >');
            $elem.after($label);
        }

        $wrapper.on('click', function (e) {
            e.stopPropagation();
        });

        ko.bindingHandlers.checked.init(element, valueAccessor, allBindings);
    }
};
