import {
    CatererApiClient, ErrorApiClient, ManageApiClient, PingApiClient, ProDomainApiClient, ValidationApiClient, WeddingPlannerApiClient } from './ApiClient';
import { Http, IHttpOptions } from './Http';

export class Api {
    public static Caterer = (options?: IHttpOptions) => new CatererApiClient(() => '', new Http(options));
    public static Error = (options?: IHttpOptions) => new ErrorApiClient(() => '', new Http(options));
    public static Manage = (options?: IHttpOptions) => new ManageApiClient(() => '', new Http(options));
    public static Ping = (options?: IHttpOptions) => new PingApiClient(() => '', new Http(options));
    public static ProDomain = (options?: IHttpOptions) => new ProDomainApiClient(() => '', new Http(options));
    public static Validation = (options?: IHttpOptions) => new ValidationApiClient(() => '', new Http(options));
    public static WeddingPlanner = (options?: IHttpOptions) => new WeddingPlannerApiClient(() => '', new Http(options));
}
