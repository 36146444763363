import i18next from 'i18next';
import { MessageType } from './Enums/MessageType';
import * as toastr from 'toastr';

export function displayMessage(
    message: string,
    title: string | null,
    type: MessageType,
    sticky?: boolean) {
    let toastrFunc;
    let toastrTitle;
    switch (type) {
        case MessageType.ERROR:
            toastrTitle = i18next.t('shared:Error');
            toastrFunc = toastr.error;
            sticky = sticky === undefined ? true : sticky;
            break;
        case MessageType.WARNING:
            toastrTitle = i18next.t('shared:Warning');
            toastrFunc = toastr.warning;
            break;
        case MessageType.INFO:
            toastrTitle = i18next.t('shared:Information');
            toastrFunc = toastr.info;
            break;
        default:
            toastrTitle = i18next.t('shared:Success');
            toastrFunc = toastr.success;
            break;
    }

    toastrTitle = title || toastrTitle;

    toastr.options.closeButton = true;
    toastr.options.debug = false;
    toastr.options.newestOnTop = true;
    toastr.options.progressBar = true;
    toastr.options.positionClass = 'toast-top-right';
    toastr.options.preventDuplicates = true;
    toastr.options.onclick = undefined;
    toastr.options.showDuration = 300;
    toastr.options.hideDuration = 1000;
    toastr.options.timeOut = sticky ? 0 : 10000;
    toastr.options.extendedTimeOut = sticky ? 0 : 1000;
    toastr.options.showEasing = 'swing';
    toastr.options.hideEasing = 'linear';
    toastr.options.showMethod = 'fadeIn';
    toastr.options.hideMethod = 'fadeOut';

    if (message) {
        toastrFunc(message, toastrTitle);
    }
}
