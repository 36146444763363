import * as $ from 'jquery';
import { isError, isString, replace } from 'lodash-es';
import { fromError, getSync } from 'stacktrace-js';
import i18next from 'i18next';
import { displayMessage } from './MessageService';
import { MessageType } from './Enums/MessageType';
import { Api } from './Api';

let unloaded = false;
$(window).bind('beforeunload', () => {
    unloaded = true;
});

export class ErrorService {
    public static manageAjaxRequestError(xhr: any) {
        if (xhr.status !== 0 || xhr.statusText !== 'abort') {
            let err;
            if (xhr.responseText && xhr.responseText[0] === '{') {
                try {
                    const error = JSON.parse(xhr.responseText);
                    err = (error.Message ? '<p>' + error.Message + '</p>' : '') +
                        (error.MessageDetail ? '<p>' + error.MessageDetail + '</p>' : '');
                } catch (e) {
                    err = '<p>' + i18next.t('shared:AjaxRequestError') + '</p>';
                }
            } else if (xhr.responseText) {
                try {
                    const htmlError = xhr.responseText.match(/.*<title.*>([\s\S]*)<\/title>.*/);
                    err = '<p>' + htmlError[1] + '</p>';
                } catch (e) {
                    err = '<p>' + xhr.responseText + '</p>';
                }
            } else {
                err = '<p>' + i18next.t('shared:AjaxRequestError') + '</p>';
            }

            if (this.doNotProcessMessage(err)) return;

            if (!unloaded) {
                const title = i18next.t('shared:Error');
                displayMessage(
                    err,
                    title,
                    MessageType.ERROR);
            }
        }
    }

    public static manageError(error: any, exception: any, stack: any, displayError: boolean = false) {
        if (!error && !exception) {
            return;
        }

        const jsException = isError(exception) ? exception : isError(error) ? error : null;
        const stacktrace = stack && stack.join ? stack.join('\n\t  at ') : stack;
        const message = jsException ? (jsException.message || jsException.name)
            : isString(error) ? error
                : isString(exception) ? exception
                    : JSON.stringify(error);
        const fileName = document.location;
        const logMessage = message + '\n  at document path ' + fileName;

        const getStackPromise = jsException ? fromError(jsException) : Promise.resolve(getSync());

        if (this.doNotProcessMessage(message)) return;

        getStackPromise
            .then((stackframes: StackTrace.StackFrame[]) => {
                this.sendError(logMessage, JSON.stringify(stackframes || stacktrace));
            })
            .catch((err: any) => {
                const errorMessage = 'ErrorService.manageError failed to get stacktrace: '
                    + err.message
                    + '\n\nOriginal error:\n' + logMessage;

                this.sendError(errorMessage, JSON.stringify(stacktrace));
            });

        if (displayError && !unloaded) {
            const title = i18next.t('shared:Error');
            displayMessage(
                message,
                title,
                MessageType.ERROR);
        }
    }

    private static doNotProcessMessage(error: string) {
        if (error) {
            let errorCaseInsentive = error.toLowerCase();
            if (errorCaseInsentive.indexOf('blocked a frame with origin') > -1) {
                return true;
            }
            else if (errorCaseInsentive.startsWith('script error'))
                return true;
        }
        return false;
    }

    private static basePropertyOf(object: any) {
        return function (key: string) {
            return object == null ? undefined : object[key];
        };
    }

    private static sendError(message: string, stacktrace: string) {
        const sanitizedMessage = replace(message, /[<>]/g, this.basePropertyOf({
            '<': '&lt;',
            '>': '&gt;',
        }));
        const sanitizedStacktrace = replace(stacktrace, /[<>]/g, this.basePropertyOf({
            '<': '&lt;',
            '>': '&gt;',
        }));
        Api.Error().logJavaScriptError({
            message: sanitizedMessage,
            stacktrace: sanitizedStacktrace,
        });
    }
}
