import { dom, library } from '@fortawesome/fontawesome-svg-core';
import {
    faFacebookF,
    faFacebookSquare,
    faGoogle,
    faInstagram,
    faPinterestP,
    faTwitter,
    faWindows,
} from '@fortawesome/free-brands-svg-icons';
import {
    faCalendar,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faClock,
    faEnvelope,
    faEye,
    faGavel,
    faGlobe,
    faHome,
    faInfoCircle,
    faLock,
    faMap,
    faMapMarkerAlt,
    faPencilAlt,
    faPhoneSquare,
    faPlay,
    faPlus,
    faPowerOff,
    faPuzzlePiece,
    faSearch,
    faTasks,
    faTimes,
    faUpload,
    faUtensils,
    faUser,
    faWrench,
} from '@fortawesome/free-solid-svg-icons';

export class FontAwesomeLoader {
    public static load() {
        library.add(
            faFacebookF,
            faFacebookSquare,
            faGoogle,
            faInstagram,
            faPinterestP,
            faTwitter,
            faWindows,
            faCalendar,
            faCheck,
            faChevronDown,
            faChevronLeft,
            faChevronRight,
            faChevronUp,
            faClock,
            faEnvelope,
            faEye,
            faGavel,
            faGlobe,
            faGoogle,
            faHome,
            faInfoCircle,
            faLock,
            faMap,
            faMapMarkerAlt,
            faPencilAlt,
            faPhoneSquare,
            faPlay,
            faPlus,
            faPowerOff,
            faPuzzlePiece,
            faSearch,
            faTasks,
            faTimes,
            faUpload,
            faUser,
            faUtensils,
            faWrench,
        );

        dom.watch();
    }
}
