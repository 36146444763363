﻿import { PageLoaderService } from '../Services/PageLoaderService';
import { BrowserInfoService } from '../Services/BrowserInfoService';
import { Browser } from '../Services/Enums/Browser';
import { CookieConsentService } from '../Services/CookieConsentService';


import $ from 'jquery';
import * as ko from 'knockout';
import AOS from 'aos';

function bindJquery() {
    $.seatingplan('html').bindJquery();
}

function _DelayedInitLayout() {
    loadAsynchronousView();
    $(document).ajaxComplete(bindJquery);
    bindJquery();
}

function loadAsynchronousView() {
    const site = {
        baseUrl: ''
    };
    $('.partialContents').each(function (index, item) {
        const url = site.baseUrl + $(item).data('url');
        if (url && url.length > 0) {
            $.get(url, function (data) {
                $(item).append(data);
            });
        }
    });
}

function checkIfBrowserIsCompatible() {
    const browserInfoElt = $('.browser-info')[0];

    if (browserInfoElt) {
        let browserInfoViewModel = ko.dataFor(browserInfoElt);

        if (!browserInfoViewModel || browserInfoViewModel.type !== 'BrowserInfoViewModel') {
            const browserInfo = new BrowserInfoService().getBrowserInfo();
            browserInfo.type = 'BrowserInfoViewModel';

            browserInfoViewModel = {
                Browser: Browser,
                data: browserInfo
            };

            ko.applyBindings(browserInfoViewModel, browserInfoElt);
        }
    }
}

function InitLayout() {
    $.seatingplan(document).pageReady(function () {
        checkIfBrowserIsCompatible();
        PageLoaderService.update(66);

        AOS.init();
        setTimeout(_DelayedInitLayout, 10);
    });
}

window.InitLayout = InitLayout;

function EndInit() {
    $.seatingplan(document).pageReady(function () {
        PageLoaderService.update(100);
    });
}

window.EndInit = EndInit;
window.GetCookieConsent = CookieConsentService.getCookieConsent;