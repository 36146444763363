import * as $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation/dist/additional-methods';
import 'jquery-validation-unobtrusive';

import 'moment/locale/de';
import * as moment from 'moment';
import i18next from 'i18next';

export class VendorConfigDe {
    public static init() {
        const language = 'de';

        moment.locale(language);

        i18next.changeLanguage(language);
        $.extend($.validator.methods, {
            min: (value: any, element: any, param: any) => {
                return ($.validator as any).prototype.optional(element) || value.replace(',', '.') >= param;
            },
            max: (value: any, element: any, param: any) => {
                return ($.validator as any).prototype.optional(element) || value.replace(',', '.') <= param;
            },
            range: (value: any, element: any, param: any) => {
                value = value.replace(',', '.');
                return ($.validator as any).prototype.optional(element) || value >= param[0] && value <= param[1];
            },
            date: (value: any, element: any) => {
                return ($.validator as any).prototype.optional(element) || /^\d{1,2}[/-]\d{1,2}[/-]\d{4}( \d{1,2}:\d{2})?$/.test(value);
            }
        });
    }
}