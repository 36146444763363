import * as ko from 'knockout';
import $ from 'jquery';

ko.utils.uniqueId = (function () {
    const prefixesCounts = {
        'ko-unique-': 0
    };

    return function (prefix) {
        prefix = prefix || 'ko-unique-';

        if (!prefixesCounts[prefix]) {
            prefixesCounts[prefix] = 0;
        }

        return prefix + prefixesCounts[prefix]++;
    };
})();

let popoverDomDataTemplateKey = '__popoverTemplateKey__';

ko.bindingHandlers.popover = {
    init: function (element) {
        const $element = $(element);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            if ($element.data('bs.popover')) {
                $element.popover('dispose');
            }
        });
    },

    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        const $element = $(element);
        const value = ko.unwrap(valueAccessor());
        const options = (!value.options && !value.template ? ko.mapping.toJS(value) : ko.mapping.toJS(value.options)) || {};

        if (value.template) {
            // use unwrap to track dependency from template, if it is observable
            ko.unwrap(value.template);

            let id = ko.utils.domData.get(element, popoverDomDataTemplateKey);

            const renderPopoverTemplate = function (eventObject) {
                if (eventObject && eventObject.type === 'inserted') {
                    $element.off('shown.bs.popover');
                }
                const template = ko.unwrap(value.template);
                let internalModel;

                if (typeof template === 'string') {
                    internalModel = {
                        $$popoverTemplate: $.extend({
                            name: value.template,
                            data: value.data
                        }, value.templateOptions)
                    };
                } else {
                    internalModel = {
                        $$popoverTemplate: value.template
                    };
                }

                const childContext = bindingContext.createChildContext(bindingContext.$rawData, null, function (context) {
                    ko.utils.extend(context, internalModel);
                });

                ko.applyBindingsToDescendants(childContext, document.getElementById(id));

                // bootstrap's popover calculates position before template renders,
                // so we recalculate position, using bootstrap methods
                $element.popover('update');
            };

            // if there is no generated id - popover executes first time for this element
            if (!id) {
                id = ko.utils.uniqueId('ks-popover-');
                ko.utils.domData.set(element, popoverDomDataTemplateKey, id);

                // place template rendering after popover is shown, because we don't have root element for template before that
                $element.on('shown.bs.popover inserted.bs.popover', renderPopoverTemplate);
            }

            options.content = '<div id="' + id + '" ><div data-bind="template: $$popoverTemplate"></div></div>';
            options.html = true;
        }

        const popoverData = $element.data('bs.popover');

        if (!popoverData) {
            $element.popover(options);

            $element.on('shown.bs.popover inserted.bs.popover', function () {
                (options.container ? $(options.container) : $element.parent()).one('click', '[data-dismiss="popover"]', function () {
                    $element.popover('hide');
                });
            });
        } else {
            ko.utils.extend(popoverData.options, options);
            if (popoverData.options.content) {
                $element.popover('show');
            } else {
                $element.popover('hide');
            }
        }
    }
};
