import * as ko from 'knockout';
import $ from 'jquery';
import { utc } from 'moment';

ko.bindingHandlers.datePicker = {
    init: function (element, valueAccessor) {
        // Register change callbacks to update the model
        // if the control changes.
        ko.utils.registerEventHandler(element, 'input', function () {
            const value = valueAccessor();
            value(utc(element.value, 'YYYY-MM-DDTHH:mm:ss.sssZ') || utc(element.value, 'L'));
        });
    },
    // Update the control whenever the view model changes
    update: function (element, valueAccessor) {
        const value = valueAccessor()();

        if (value && !isNaN(value)) {
            element.value = value.toISOString().slice(0, 10) || utc(value, 'L') && utc(value, 'L').format('L');
        }

        $(element).data('initialized', true);
    }
};
