import $ from 'jquery';
import * as ko from 'knockout';
import 'lazysizes';

import { PageLoaderService } from '../../Services/PageLoaderService';
import ViewModel from './Models/ViewModel';

function InitHomeIndex() {
    $.seatingplan(document).pageReady(() => {
        const vm = new ViewModel();
        ko.applyBindings(vm);
        PageLoaderService.update(100);
    });
}

window.InitHomeIndex = InitHomeIndex;
