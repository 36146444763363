import {
    Api,
} from '../../Services/Api';

export default class Repository {
    constructor(configuration) {
        this.configuration = configuration;
    }

    getProDomain() {
        return Api.ProDomain({
            showAjaxLoader: true,
            updatePageLoader: true,
        }).loadProDomain(this.configuration.id);
    }

    saveInfo(name, description, isSearchEngineIndexed) {
        return Api.ProDomain({
            showAjaxLoader: true,
            ajaxLoader: '.domain-info > .AjaxLoader',
        }).updateInfo(this.configuration.id, {
            name,
            description,
            isSearchEngineIndexed,
        });
    }

    deletePicture(id) {
        return Api.ProDomain({
            showAjaxLoader: false,
        }).deletePicture(id);
    }

    saveRooms(rooms) {
        return Api.ProDomain({
            showAjaxLoader: true,
            ajaxLoader: '.domain-rooms > .AjaxLoader',
        }).updateRooms(this.configuration.id, rooms);
    }

    saveContact(address, phoneNumbers, emails, website) {
        return Api.ProDomain({
            showAjaxLoader: true,
            ajaxLoader: '.domain-contact > .AjaxLoader',
        }).updateContact(this.configuration.id, {
            address,
            phoneNumbers,
            emails,
            website,
        });
    }

    saveTablesChairs(tablesChairs) {
        return Api.ProDomain({
            showAjaxLoader: true,
            ajaxLoader: '.domain-tables-chairs > .AjaxLoader',
        }).updateTablesChairs(this.configuration.id, tablesChairs);
    }

    saveServices(services) {
        return Api.ProDomain({
            showAjaxLoader: true,
            ajaxLoader: '.domain-services > .AjaxLoader',
        }).updateServices(this.configuration.id, services);
    }

    saveCatering(catering) {
        return Api.ProDomain({
            showAjaxLoader: true,
            ajaxLoader: '.domain-catering > .AjaxLoader',
        }).updateCatering(this.configuration.id, catering);
    }

    saveAccommodation(accommodation) {
        return Api.ProDomain({
            showAjaxLoader: true,
            ajaxLoader: '.domain-accommodation > .AjaxLoader',
        }).updateAccommodation(this.configuration.id, accommodation);
    }

    validateRoomPlan(proDomainRoomId) {
        return Api.ProDomain({
            showAjaxLoader: true,
            ajaxLoader: '.domain-rooms > .AjaxLoader',
        }).validateRoomPlan(proDomainRoomId);
    }
}