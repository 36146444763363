import piexifjs from 'piexifjs';
import workerService from '../WorkerService';
import scaleImageWorker from '../Workers/ScaleImageCustom.worker';

/* globals document */
function scaleImage(inputFile, maxWidth, quality) {
    return new Promise(((resolve) => {
        const img = new Image();
        img.addEventListener('load', () => {
            let canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const context = canvas.getContext('2d');

            getImageOrientation(inputFile).then((orientation) => {
                switch (orientation) {
                    case 3:
                        // 180° rotate left
                        drawRotated(context, canvas, img, -180);
                        break;
                    case 6:
                        // 90° rotate right
                        drawRotated(context, canvas, img, 90);
                        break;
                    case 8:
                        // 90° rotate left
                        drawRotated(context, canvas, img, -90);
                        break;
                    default:
                        context.drawImage(img, 0, 0, canvas.width, canvas.height);
                        break;
                }

                maxWidth = maxWidth ? maxWidth : 1024;
                quality = quality ? quality : 1.00;

                if (0.00 >= quality || quality > 1.00) {
                    quality = 1.00;
                }

                while (canvas.width >= 2 * maxWidth) {
                    canvas = getHalfScaleCanvas(canvas);
                }

                if (canvas.width > maxWidth) {
                    scaleCanvasWithAlgorithm(canvas, maxWidth, quality, resolve);
                } else {
                    const imageData = canvas.toDataURL('image/jpeg', quality);
                    resolve(imageData);
                }
            });
        });
        img.src = URL.createObjectURL(inputFile);
    }));
}

function getImageOrientation(inputFile) {
    return new Promise(((resolve) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            try {
                const exifObj = piexifjs.load(e.target.result);
                const orientation = exifObj && exifObj['0th'] ? exifObj['0th'][piexifjs.ImageIFD.Orientation] : 0;
                resolve(orientation);
            } catch (_err) {
                resolve(0);
            }
        };
        fileReader.readAsDataURL(inputFile);
    }));
}

function drawRotated(context, canvas, image, degrees) {
    context.clearRect(0, 0, canvas.width, canvas.height);

    // save the unrotated context of the canvas so we can restore it later
    // the alternative is to untranslate & unrotate after drawing
    context.save();

    // move to the center of the canvas
    context.translate(canvas.width / 2, canvas.height / 2);

    // rotate the canvas to the specified degrees
    context.rotate(degrees * Math.PI / 180);

    // draw the image
    // since the context is rotated, the image will be rotated also
    context.drawImage(image, -image.width / 2, -image.height / 2);

    // we’re done with the rotating so restore the unrotated context
    context.restore();
}

function scaleCanvasWithAlgorithm(canvas, maxWidth, quality, resolve) {
    const scaledCanvas = document.createElement('canvas');

    const scale = maxWidth / canvas.width;

    scaledCanvas.width = canvas.width * scale;
    scaledCanvas.height = canvas.height * scale;

    const srcImgData = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height);
    const destImgData = scaledCanvas.getContext('2d').createImageData(scaledCanvas.width, scaledCanvas.height);


    const parameters = {
        imageSrc: srcImgData,
        imageDest: destImgData,
        scale,
    };

    workerService.callWorker(scaleImageWorker, parameters).then((img) => {
        scaledCanvas.getContext('2d').putImageData(img, 0, 0);
        resolve(scaledCanvas.toDataURL('image/jpeg', quality));
    }, () => {
        resolve(canvas.toDataURL('image/jpeg', quality));
    });
}

function getHalfScaleCanvas(canvas) {
    const halfCanvas = document.createElement('canvas');
    halfCanvas.width = canvas.width / 2;
    halfCanvas.height = canvas.height / 2;

    halfCanvas.getContext('2d').drawImage(canvas, 0, 0, halfCanvas.width, halfCanvas.height);

    return halfCanvas;
}

export default {
    scaleImage,
};