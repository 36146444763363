import * as ko from 'knockout';
import $ from 'jquery';

ko.bindingHandlers.drag = {
    init: function (element, valueAccessor) {
        const defaultOptions = {
            containment: 'parent',
            cursor: 'move',
            opacity: 0.7
        };

        const options = valueAccessor();
        $.extend(defaultOptions, options);

        $(element).draggable(defaultOptions);
    }
};
