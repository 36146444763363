import 'jquery-validation';
import 'jquery-validation/dist/additional-methods';
import 'jquery-validation-unobtrusive';

//import 'moment/locale/en'; it is default language so no import
import * as moment from 'moment';
import i18next from 'i18next';

export class VendorConfigEn {
    public static init() {
        const language = 'en';

        moment.locale(language);
        
        i18next.changeLanguage(language);
    }
}