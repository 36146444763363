import * as ko from 'knockout';
import Repository from './Repository';
import ViewModel from './Models/ViewModel';
import '../../css/Views/ProDomain.scss';

export default class Controller {
    constructor(config) {
        this.repository = new Repository(config);
        this.options = config;
    }

    create() {
        this.repository
            .getProDomain()
            .then((proDomain) => {
                const vm = new ViewModel(this.repository, this.options, proDomain);
                ko.applyBindings(vm);
            });
    }
}
