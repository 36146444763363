import { VendorConfigDe } from './vendor.config.de';
import { VendorConfigEn } from './vendor.config.en';
import { VendorConfigEs } from './vendor.config.es';
import { VendorConfigFr } from './vendor.config.fr';
import { VendorConfigIt } from './vendor.config.it';
import { VendorConfigPt } from './vendor.config.pt';


import { configureI18n } from 'config/i18n';

import moment from 'moment';

configureI18n();

window.moment = moment;

switch (window.language) {
  case 'de':
    VendorConfigDe.init();
    break;

  case 'es':
    VendorConfigEs.init();
    break;

  case 'fr':
    VendorConfigFr.init();
    break;

  case 'it':
    VendorConfigIt.init();
    break;

  case 'pt':
    VendorConfigPt.init();
    break;

  default:
    VendorConfigEn.init();
    break;
}
