import $ from 'jquery';
import { PageLoaderService } from '../../Services/PageLoaderService';

function InitHomeContact() {
    $.seatingplan(document).pageReady(function () {
        PageLoaderService.update(100);
    });
}

window.InitHomeContact = InitHomeContact;
