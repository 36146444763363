
import { Api } from './Api';


export class CookieConsentService {

    public static getCookieConsent() {
        Api.Manage().getCookieConsent();
    }
    
    
}
