import './css/BootstrapTheming.scss';

import 'css-toggle-switch/dist/toggle-switch.css';
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.css';
import 'toastr/build/toastr.css';
import 'offline-js/themes/offline-theme-chrome.css';
import 'blueimp-file-upload/css/jquery.fileupload.css';
import 'lightbox2/dist/css/lightbox.css';
import 'aos/dist/aos.css';
import 'animate.css/animate.css';

import './css/Components/index.scss';
import './css/Site.scss';
import './css/Views/Account.scss';
import './css/Views/Caterer.scss';
import './css/Views/Error.scss';
import './css/Views/Home.scss';
import './css/Views/ProDomain.scss';
import './css/Views/WeddingPlanner.scss';
import './css/Views/Contact.scss';
import './css/Views/Privacy.scss';
import './css/Override.scss';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { FontAwesomeLoader } from './FontAwesomeLoader';
import { ServiceWorkerService } from './Services/ServiceWorkerService';

import * as ko from 'knockout';
import 'knockout-mapping';
import 'knockout-postbox';

import 'isomorphic-fetch';
import 'popper.js';
import 'bootstrap';
import 'offline-js';

import './vendor.config';
import './Extensions';
import './knockout.extensions';

import './ViewScripts/seatingplan.core';
import './ViewScripts/FacebookInit';
import './ViewScripts/Layout';

import './ViewScripts/Account';
import './ViewScripts/Manage';

import './ViewScripts/Home';
import './ViewScripts/Home/Privacy';
import './ViewScripts/Home/Contact';
import './ViewScripts/Home/Partners';
import './ViewScripts/Home/Terms';
import './ViewScripts/Home/ServicesPricing';

import './ViewScripts/Caterer';
import './ViewScripts/ProDomain';
import './ViewScripts/WeddingPlanner';



// Basic hot reloading support. Automatically reloads and restarts the Knockout app each time
// you modify source files. This will not preserve any application state other than the URL.
// if (module.hot) {
//     module.hot.accept();
//     module.hot.dispose(() => ko.cleanNode(document.body));
// }

FontAwesomeLoader.load();
ServiceWorkerService.register();
