import * as ko from 'knockout';
import $ from 'jquery';
import Controller from './Controller';
import { PageLoaderService } from '../../Services/PageLoaderService';

function InitWeddingPlannerIndex(config) {
    $.seatingplan(document).pageReady(() => {
        new Controller(config).create();
    });
}

window.InitWeddingPlannerIndex = InitWeddingPlannerIndex;

