import {
    map,
} from 'lodash-es';
import { Loader } from 'google-maps';
import i18next from 'i18next';

/* globals window */
function buildAddress(item) {
    const location = {
        coords: {
            latitude: item.geometry.location.lat(),
            longitude: item.geometry.location.lng(),
            altitude: null,
            accuracy: null,
            altitudeAccuracy: null,
            heading: null,
            velocity: null,
        },
        address: {
            city: null,
            country: null,
            countryCode: null,
            county: null,
            postalCode: null,
            premises: null,
            region: null,
            street: null,
            streetNumber: null,
        },
        formattedAddress: item.formatted_address,
    };

    for (let i = 0; i < item.address_components.length; i++) {
        const component = item.address_components[i];

        switch (component.types[0]) {
            case 'street_number':
                location.address.streetNumber = component.long_name;
                break;
            case 'route':
                location.address.street = component.long_name;
                break;
            case 'locality':
                location.address.city = component.long_name;
                break;
            case 'administrative_area_level_2':
                location.address.county = component.long_name;
                break;
            case 'administrative_area_level_1':
                location.address.region = component.long_name;
                break;
            case 'country':
                location.address.country = component.long_name;
                location.address.countryCode = component.short_name;
                break;
            case 'postal_code':
                location.address.postalCode = component.long_name;
                break;

            default:
                break;
        }
    }

    return location;
}

function searchAddress(address, googleMapKey) {
    return new window.Promise(((resolve, reject) => {

        window.googleLoader = window.googleLoader || new Loader(googleMapKey, {});

        window.googleLoader.load().then(function (google) {
            const geocoder = new google.maps.Geocoder();
            const query = {
                address,
            };
            geocoder.geocode(query, (result, status) => {
                switch (status) {
                    case google.maps.GeocoderStatus.ZERO_RESULTS:
                        reject({
                            type: 'zero',
                            message: i18next.t('geolocation:ZERO_RESULTS') + address,
                        });
                        break;

                    case google.maps.GeocoderStatus.OVER_QUERY_LIMIT:
                        reject({
                            type: 'over_query_limit',
                            message: i18next.t('geolocation:OVER_QUERY_LIMIT') + address,
                        });
                        break;

                    case google.maps.GeocoderStatus.REQUEST_DENIED:
                        reject({
                            type: 'denied',
                            message: i18next.t('geolocation:REQUEST_DENIED') + address,
                        });
                        break;

                    case google.maps.GeocoderStatus.INVALID_REQUEST:
                        reject({
                            type: 'invalid',
                            message: i18next.t('geolocation:INVALID_REQUEST') + address,
                        });
                        break;

                    case google.maps.GeocoderStatus.OK:
                    default:
                        if (result.length > 0) {
                            resolve(map(result, buildAddress));
                        } else {
                            reject({
                                type: 'zero',
                                message: i18next.t('geolocation:ZERO_RESULTS') + address,
                            });
                        }
                        break;
                }
            });
        });
    }));
}

export default {
    searchAddress,
};