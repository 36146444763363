import * as ko from 'knockout';
import Repository from './Repository';
import ViewModel from './Models/ViewModel';
import '../../css/Views/WeddingPlanner.scss';

export default class Controller {
    constructor(config) {
        this.repository = new Repository(config);
        this.options = config;
    }

    create() {
        this.repository
            .getWeddingPlanner()
            .then((weddingPlanner) => {
            	const vm = new ViewModel(this.repository, this.options, weddingPlanner);
                ko.applyBindings(vm);
            });
    }
}
