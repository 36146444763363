const v4 = '(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])(?:\\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){3}';

const v6Seg = '[0-9a-fA-F]{1,4}';
const v6 = `
(
(?:${v6Seg}:){7}(?:${v6Seg}|:)|                                // 1:2:3:4:5:6:7::  1:2:3:4:5:6:7:8
(?:${v6Seg}:){6}(?:${v4}|:${v6Seg}|:)|                         // 1:2:3:4:5:6::    1:2:3:4:5:6::8   1:2:3:4:5:6::8  1:2:3:4:5:6::1.2.3.4
(?:${v6Seg}:){5}(?::${v4}|(:${v6Seg}){1,2}|:)|                 // 1:2:3:4:5::      1:2:3:4:5::7:8   1:2:3:4:5::8    1:2:3:4:5::7:1.2.3.4
(?:${v6Seg}:){4}(?:(:${v6Seg}){0,1}:${v4}|(:${v6Seg}){1,3}|:)| // 1:2:3:4::        1:2:3:4::6:7:8   1:2:3:4::8      1:2:3:4::6:7:1.2.3.4
(?:${v6Seg}:){3}(?:(:${v6Seg}){0,2}:${v4}|(:${v6Seg}){1,4}|:)| // 1:2:3::          1:2:3::5:6:7:8   1:2:3::8        1:2:3::5:6:7:1.2.3.4
(?:${v6Seg}:){2}(?:(:${v6Seg}){0,3}:${v4}|(:${v6Seg}){1,5}|:)| // 1:2::            1:2::4:5:6:7:8   1:2::8          1:2::4:5:6:7:1.2.3.4
(?:${v6Seg}:){1}(?:(:${v6Seg}){0,4}:${v4}|(:${v6Seg}){1,6}|:)| // 1::              1::3:4:5:6:7:8   1::8            1::3:4:5:6:7:1.2.3.4
(?::((?::${v6Seg}){0,5}:${v4}|(?::${v6Seg}){1,7}|:))           // ::2:3:4:5:6:7:8  ::2:3:4:5:6:7:8  ::8             ::1.2.3.4
)(%[0-9a-zA-Z]{1,})?                                           // %eth0            %1
`.replace(/\s*\/\/.*$/gm, '').replace(/\n/g, '').trim();

const ip = (opts) => opts && opts.exact ?
    new RegExp(`(?:^${v4}$)|(?:^${v6}$)`) :
    new RegExp(`(?:${v4})|(?:${v6})`, 'g');

ip.v4 = (opts) => opts && opts.exact ? new RegExp(`^${v4}$`) : new RegExp(v4, 'g');
ip.v6 = (opts) => opts && opts.exact ? new RegExp(`^${v6}$`) : new RegExp(v6, 'g');

export default ip;
