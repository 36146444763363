import * as ko from 'knockout';
import $ from 'jquery';

ko.bindingHandlers.dropFile = {
    init: function (element, valueAccessor) {
        const elt = $(element);
        elt.addClass('drop-zone');

        const inputFile = $('<input type="file" class="hidden-input-file"/>');
        inputFile.insertAfter(elt);

         elt.on('click', function (event) {
            if($(event.target).hasClass('drop-zone-hint')){
                event.preventDefault();
                event.stopPropagation();
                inputFile.click();
                return false;
            }
            return true;
        });

        elt.on('dragover', function (event) {
            event.preventDefault();
            event.stopPropagation();
            elt.addClass('drag-over');
        });

        elt.on('dragleave', function (event) {
            event.preventDefault();
            event.stopPropagation();
            elt.removeClass('drag-over');
        });

        elt.on('drop', function (event) {
            event.preventDefault();
            event.stopPropagation();
            elt.removeClass('drag-over');

            const file = event.originalEvent.dataTransfer.files[0];
            if (file) {
                valueAccessor()(file);
            }
        });

        inputFile.on('change', function (event) {
            event.preventDefault();
            event.stopPropagation();

            const file = this.files[0];
            if (file) {
                valueAccessor()(file);
            }
        });
    }
};
