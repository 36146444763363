import $ from 'jquery';
import 'bootstrap-social/bootstrap-social.css';
import { PageLoaderService } from '../../Services/PageLoaderService';


function InitManageDefault() {
    $.seatingplan(document).pageReady(function () {
        PageLoaderService.update(100);
    });
}

window.InitManageDefault = InitManageDefault;
