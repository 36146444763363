import $ from 'jquery';
import { PageLoaderService } from '../../Services/PageLoaderService';

function InitHomeTerms() {
    $.seatingplan(document).pageReady(function () {
        PageLoaderService.update(100);

        const $iframe = $('iframe#terms');
        $iframe.ready(function () {
            $iframe.contents().find('body').append($('#termsContent').val());
            setTimeout(function () {
                $iframe.height($iframe.contents().find('body').height() + 50);
            }, 1000);
        });
    });
}

window.InitHomeTerms = InitHomeTerms;
