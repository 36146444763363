import * as ko from 'knockout';
import {
    assign,
    forEach,
} from 'lodash-es';
import $ from 'jquery';
import i18next from 'i18next';
import geolocationService from '../../../Services/GeolocationService';
import {
    displayMessage,
} from '../../../Services/MessageService';
import {
    MessageType,
} from '../../../Services/Enums/MessageType';
import ProDomainRoomModel from './ProDomainRoomModel';
import PictureModel from '../../Shared/Picture/PictureModel';

/* globals atob, document, Blob */
export default class ProDomainModel {
    constructor(proDomain, mapping, repository, options) {
        this.repository = repository;
        this.options = options;
        this.mapping = mapping;

        this.infoType = {
            Info: 1,
            Pictures: 2,
            Rooms: 3,
            Contact: 4,
            Catering: 5,
            Accommodation: 6,
            Services: 7,
            TablesChairs: 8,
        };

        this.infoSections = {};
        this.infoSections[this.infoType.Info] = 'domain-info';
        this.infoSections[this.infoType.Pictures] = 'domain-pictures';
        this.infoSections[this.infoType.Rooms] = 'domain-rooms';
        this.infoSections[this.infoType.Contact] = 'domain-contact';
        this.infoSections[this.infoType.Catering] = 'domain-catering';
        this.infoSections[this.infoType.Accommodation] = 'domain-accommodation';
        this.infoSections[this.infoType.Services] = 'domain-services';
        this.infoSections[this.infoType.TablesChairs] = 'domain-tables-chairs';

        this.data = {};

        this.isEditing = {};

        this.initialValues = proDomain;
        this.$type = 'ProDomainModel';

        this.autocompleteSelectedItem = undefined;
        this.autocomplete = {
            items: ko.observableArray([]),
            show: ko.observable(false),
        };
        this.searchLocation = ko.observable().extend({
            rateLimit: {
                method: 'notifyWhenChangesStop',
                timeout: 500,
            },
        });
        this.searchLocation.subscribe(() => {
            this.populateAutocomplete();
        });

        this.fileUpload = {
            onAdd: (e, data) => this.addPicture(e, data),
            onProgress: (e, data) => this.fileUploadProgress(e, data),
            onProgressAll: (e, data) => this.fileUploadProgressAll(e, data),
            onDone: (e, data) => this.fileUploadDone(e, data),
            progress: ko.observable(0),
            extendedProgress: ko.observable(''),
            acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
        };

        this.init(proDomain);
    }

    saveInfo() {
        this.repository
            .saveInfo(this.data.name(), this.data.description(), this.data.isSearchEngineIndexed())
            .then((result) => {
                displayMessage(
                    result.message,
                    null,
                    result.success ? MessageType.SUCCESS : MessageType.ERROR,
                );

                this.isEditing[this.infoType.Info](!result.success);

                if (result.success) {
                    this.data.id(result.id);
                    this.saveChanges(this.infoType.Info);
                }
            });
    }

    savePictures() {
        this.isEditing[this.infoType.Pictures](false);
        this.saveChanges(this.infoType.Pictures);
    }

    deletePicture(picture) {
        if (!picture.isDeleting) {
            picture.isDeleting = true;
            this.repository
                .deletePicture(picture.id())
                .then((result) => {
                    displayMessage(
                        result.message,
                        null,
                        result.success ? MessageType.SUCCESS : MessageType.ERROR,
                    );

                    if (result.success) {
                        this.data.pictures.remove(picture);
                    } else {
                        picture.isDeleting = false;
                    }
                });
        }
    }

    addProDomainRoom() {
        const proDomainRoomModel = new ProDomainRoomModel(assign({}, this.options.defaultProDomainRoom), this.mapping, this.repository, this.options);
        proDomainRoomModel.isEditing(true);
        if (!this.data.rooms || !this.data.rooms()) {
            this.data.rooms = ko.observableArray();
        }
        this.data.rooms.push(proDomainRoomModel);
    }

    removeProDomainRoom(item) {
        this.data.rooms.remove(item);
    }

    saveRooms() {
        const jsRooms = ko.mapping.toJS(this.data.rooms);
        //Force maxGuests to int. Don't know why at this point maxGuests is a string instead of number.
        jsRooms.forEach(room => { room.maxGuests = room.maxGuests ? parseInt(room.maxGuests) : 0; });
        this.repository
            .saveRooms(jsRooms)
            .then((result) => {
                displayMessage(
                    result.message,
                    null,
                    result.success ? MessageType.SUCCESS : MessageType.ERROR,
                );

                this.isEditing[this.infoType.Rooms](!result.success);

                if (result.success) {
                    this.data.id(result.id);
                    this.refreshRooms(result.data);
                }
            });
    }

    saveContact() {
        if (!this.data.address || !this.data.address.formattedAddress()) {
            displayMessage(i18next.t('pro:SelectedAddressError'), null, MessageType.ERROR);
            return;
        }

        const proDomainData = ko.mapping.toJS(this.data);
        let {
            website,
        } = proDomainData;
        if (website === '') website = null;
        this.repository
            .saveContact(
                proDomainData.address,
                proDomainData.phoneNumbers,
                proDomainData.emails,
                website,
            )
            .then((result) => {
                displayMessage(
                    result.message,
                    null,
                    result.success ? MessageType.SUCCESS : MessageType.ERROR,
                );

                this.isEditing[this.infoType.Contact](!result.success);

                if (result.success) {
                    this.data.id(result.id);
                    this.saveChanges(this.infoType.Contact);
                }
            });
    }

    saveTablesChairs() {
        this.repository
            .saveTablesChairs(this.data.tablesChairs())
            .then((result) => {
                displayMessage(
                    result.message,
                    null,
                    result.success ? MessageType.SUCCESS : MessageType.ERROR,
                );

                this.isEditing[this.infoType.TablesChairs](!result.success);

                if (result.success) {
                    this.data.id(result.id);
                    this.saveChanges(this.infoType.TablesChairs);
                }
            });
    }

    saveServices() {
        this.repository
            .saveServices(this.data.services())
            .then((result) => {
                displayMessage(
                    result.message,
                    null,
                    result.success ? MessageType.SUCCESS : MessageType.ERROR,
                );

                this.isEditing[this.infoType.Services](!result.success);

                if (result.success) {
                    this.data.id(result.id);
                    this.saveChanges(this.infoType.Services);
                }
            });
    }

    saveCatering() {
        this.repository
            .saveCatering(this.data.catering())
            .then((result) => {
                displayMessage(
                    result.message,
                    null,
                    result.success ? MessageType.SUCCESS : MessageType.ERROR,
                );

                this.isEditing[this.infoType.Catering](!result.success);

                if (result.success) {
                    this.data.id(result.id);
                    this.saveChanges(this.infoType.Catering);
                }
            });
    }

    saveAccommodation() {
        this.repository
            .saveAccommodation(this.data.accommodation())
            .then((result) => {
                displayMessage(
                    result.message,
                    null,
                    result.success ? MessageType.SUCCESS : MessageType.ERROR,
                );

                this.isEditing[this.infoType.Accommodation](!result.success);

                if (result.success) {
                    this.data.id(result.id);
                    this.saveChanges(this.infoType.Accommodation);
                }
            });
    }

    refreshRooms(rooms) {
        this.data.rooms.removeAll();

        forEach(rooms, (room) => {
            this.data.rooms.push(new ProDomainRoomModel(assign({}, room), this.mapping, this.repository, this.options));
        });

        this.saveChanges(this.infoType.Rooms);
    }

    populateAutocomplete() {
        if (this.isEditing[this.infoType.Contact]() &&
            this.searchLocation() &&
            (!this.autocompleteSelectedItem ||
                this.searchLocation() !== this.autocompleteSelectedItem.formattedAddress)) {
            geolocationService
                .searchAddress(this.searchLocation(), this.options.googleMapKey)
                .then((addresses) => {
                    this.autocomplete.items.removeAll();
                    this.autocomplete.items(addresses);
                    this.autocomplete.show(addresses.length);
                }, (error) => {
                    displayMessage(
                        error.message,
                        null,
                        MessageType.WARNING,
                    );
                });
        }
    }

    setAddress(address) {
        this.autocompleteSelectedItem = address;
        this.searchLocation(address.formattedAddress);
        this.autocomplete.show(false);
        ko.mapping.fromJS(address, this.mapping, this.data.address);
    }

    init(proDomainData) {
        ko.mapping.fromJS(proDomainData, this.mapping, this.data);
        if (this.data.address) {
            this.searchLocation(this.data.address.formattedAddress());
        }

        this.createSection(this.infoType.Info);
        this.createSection(this.infoType.Pictures);
        this.createSection(this.infoType.Rooms);
        this.createSection(this.infoType.Contact);
        this.createSection(this.infoType.Catering);
        this.createSection(this.infoType.Accommodation);
        this.createSection(this.infoType.Services);
        this.createSection(this.infoType.TablesChairs);

        $(document).bind('fileuploadsuccess', () => {
            this.repository
                .getProDomain()
                .then((updatedProDomain) => {
                    this.refreshRooms(updatedProDomain.rooms);
                });
        });
    }

    createSection(infoType) {
        this.isEditing[infoType] = ko.observable(false);
    }

    getTemplate(infoType) {
        if (this.isEditing[infoType]()) {
            return `prodomain-edit-${this.infoSections[infoType]}-template`;
        }
        return `prodomain-read-${this.infoSections[infoType]}-template`;
    }

    modifySection(infoType) {
        this.isEditing[infoType](true);
    }

    addPicture(_e, data) {
        const file = data.files[0];
        const newPicture = new PictureModel(assign({}, this.options.defaultProDomainPicture), this.mapping);
        this.data.pictures.unshift(newPicture);
        data.picture = newPicture;

        newPicture.changePicture(file, () => {
            const base64Data = data.picture.lightFileUrl().substring(data.picture.lightFileUrl().indexOf(',') + 1);
            const resizedFile = this.b64ToFile(base64Data, file.name, 'image/png');
            data.files[0] = resizedFile;
            data.submit();
        });
    }

    fileUploadProgress(_e, data) {
        if (data.picture) {
            data.picture.progress(data.progress);
        }
    }

    fileUploadProgressAll(_e, data) {
        this.fileUpload.progress(data.progress);
        this.fileUpload.extendedProgress(data.extendedProgress);
    }

    fileUploadDone(_e, data) {
        if (data.picture) {
            data.picture.id(data.result[0].data);
        }
    }

    b64ToFile(b64Data, _filename, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {
            type: contentType,
        });
        return blob;
    }

    cancelSection(infoType) {
        this.revertChanges(infoType);
        this.isEditing[infoType](false);
    }

    revertChanges(infoType) {
        switch (infoType) {
            case this.infoType.Info:
                this.data.name(this.initialValues.name);
                this.data.description(this.initialValues.description);
                this.data.isSearchEngineIndexed(this.initialValues.isSearchEngineIndexed);
                break;

            case this.infoType.Pictures:
                this.revertPicturesChanges();
                break;

            case this.infoType.Rooms:
                ko.mapping.fromJS(this.initialValues.rooms, this.mapping, this.data.rooms);
                break;

            case this.infoType.Contact:
                ko.mapping.fromJS(this.initialValues.address, this.mapping, this.data.address);
                this.searchLocation(this.data.address.formattedAddress());
                this.data.phoneNumbers(this.initialValues.phoneNumbers);
                this.data.emails(this.initialValues.emails);
                this.data.website(this.initialValues.website);
                break;

            case this.infoType.Catering:
                this.data.catering(this.initialValues.catering);
                break;

            case this.infoType.Accommodation:
                this.data.accommodation(this.initialValues.accommodation);
                break;

            case this.infoType.Services:
                this.data.services(this.initialValues.services);
                break;

            case this.infoType.TablesChairs:
                this.data.tablesChairs(this.initialValues.tablesChairs);
                break;

            default:
                break;
        }
    }

    revertPicturesChanges() {
        this.data.pictures.removeAll();
        const initialPictures = [];
        for (let i = 0; i < this.initialValues.pictures.length; i++) {
            initialPictures.push(new PictureModel(this.initialValues.pictures[i], this.mapping));
        }
        this.data.pictures(initialPictures);
    }

    saveChanges(infoType) {
        switch (infoType) {
            case this.infoType.Info:
                this.initialValues.name = this.data.name();
                this.initialValues.description = this.data.description();
                this.initialValues.isSearchEngineIndexed = this.data.isSearchEngineIndexed();
                break;

            case this.infoType.Pictures:
                this.initialValues.pictures = ko.mapping.toJS(this.data.pictures);
                break;

            case this.infoType.Rooms:
                this.initialValues.rooms = ko.mapping.toJS(this.data.rooms);
                break;

            case this.infoType.Contact:
                this.initialValues.address = ko.mapping.toJS(this.data.address);
                this.initialValues.phoneNumbers = this.data.phoneNumbers();
                this.initialValues.emails = this.data.emails();
                this.initialValues.website = this.data.website();
                break;

            case this.infoType.Catering:
                this.initialValues.catering = this.data.catering();
                break;

            case this.infoType.Accommodation:
                this.initialValues.accommodation = this.data.accommodation();
                break;

            case this.infoType.Services:
                this.initialValues.services = this.data.services();
                break;

            case this.infoType.TablesChairs:
                this.initialValues.tablesChairs = this.data.tablesChairs();
                break;

            default:
                break;
        }
    }
}