import * as $ from 'jquery';
import * as ko from 'knockout';
import { assign } from 'lodash-es';
import i18next from 'i18next';
import { PageLoaderService } from '../PageLoaderService';
import { displayMessage } from '../MessageService';
import { MessageType } from '../Enums/MessageType';
import { apiVersion } from '../../Constants';
import { ServiceWorkerService } from '../ServiceWorkerService';

export interface IHttpOptions {
    showAjaxLoader?: boolean;
    updatePageLoader?: boolean;
    ajaxLoader?: string;
}

export class Http {
    private options: IHttpOptions;
    constructor(private httpOptions?: IHttpOptions) {
        this.options = assign(
            {
                updatePageLoader: false,
                showAjaxLoader: true,
                ajaxLoader: '#body > .AjaxLoader',
            },
            httpOptions);
    }

    public fetch(url: RequestInfo, init?: RequestInit): Promise<Response> {
        const ajaxLoaderViewModel = this.getAjaxLoaderViewModel();

        this.startRequest(ajaxLoaderViewModel);

        const requestInit = init || {} as RequestInit;
        requestInit.credentials = 'include';
        return fetch(url, requestInit)
            .then((response: Response) => {
                this.checkVersion(response);
                this.endRequest(ajaxLoaderViewModel);

                return response;
            })
            .catch((response: Response) => {
                this.endRequest(ajaxLoaderViewModel);

                return response;
            });
    }

    private checkVersion(response: Response) {
        const serverApiVersion = response.headers.get('api-version');
        const newVersionReleased = serverApiVersion && serverApiVersion !== apiVersion;
        if (newVersionReleased) {
            ServiceWorkerService.unregisterAll().then(() => {
                ServiceWorkerService.update().catch(() => {
                    displayMessage(
                        i18next.t('shared:NewVersionAvailable'),
                        null,
                        MessageType.WARNING);
                });
            });
        }
    }

    private getAjaxLoader(ajaxLoader: any) {
        return $(ajaxLoader);
    }

    private getAjaxLoaderViewModel() {
        const ajaxLoaderElt = this.getAjaxLoader(this.options.ajaxLoader)[0];
        let ajaxLoaderViewModel = ko.dataFor(ajaxLoaderElt);

        if (!ajaxLoaderViewModel || ajaxLoaderViewModel.type !== 'AjaxLoaderViewModel') {
            ajaxLoaderViewModel = {
                type: 'AjaxLoaderViewModel',
                enable: ko.observable(this.options.showAjaxLoader),
                show: ko.observable(false),
                progress: ko.observable(0),
            };
            ko.applyBindings(ajaxLoaderViewModel, ajaxLoaderElt);
        } else {
            ajaxLoaderViewModel.enable(this.options.showAjaxLoader);
            ajaxLoaderViewModel.show(false);
            ajaxLoaderViewModel.progress(0);
        }

        return ajaxLoaderViewModel;
    }

    private startRequest(ajaxLoaderViewModel: any) {
        ajaxLoaderViewModel.show(true);

        if (this.options.updatePageLoader) {
            PageLoaderService.update(66);
        }
    }

    private updateRequestProgress(ajaxLoaderViewModel: any, loaded: number, total: number) {
        let progress = loaded / total * 100;
        if (progress > 100) {
            progress = 100;
        }
        if (progress < 0) {
            progress = 0;
        }
        ajaxLoaderViewModel.progress(progress);
    }

    private endRequest(ajaxLoaderViewModel: any) {
        ajaxLoaderViewModel.show(false);

        if (this.options.updatePageLoader) {
            PageLoaderService.update(100);
        }
    }
}
