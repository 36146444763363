import * as ko from 'knockout';
import { isNumber } from 'lodash-es';
import i18next from 'i18next';

ko.bindingHandlers.valueNumber = {
    init: function (element, valueAccessor) {
        const observable = valueAccessor();
        const serverDecimal = (1.1).toLocaleString(i18next.language).substring(1, 2);

        const interceptor = ko.computed({
            read: function () {
                return observable();
            },
            write: function (newValue) {
                let number = newValue;
                if (!isNumber(number)) {
                    const invariantFormatNumber = serverDecimal === '.' ? newValue : newValue.replace(serverDecimal, '.');
                    number = parseFloat(invariantFormatNumber);

                    if (isNaN(number)) {
                        number = parseFloat(newValue);
                    }
                }
                if (number === 0 || number) {
                    observable(number);
                }
            }
        });

        if (element.tagName.toLowerCase() === 'input') {
            ko.applyBindingsToNode(element, { value: interceptor });
        } else {
            ko.applyBindingsToNode(element, { text: interceptor });
        }
    }
};
