import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import { utc } from 'moment';
import urlRegex from './url-regex';

$.fn.updateValidation = function () {
    const $form = this.closest('form');
    $form.removeData('validator').removeData('unobtrusiveValidation');
    $.validator.unobtrusive.parse('#' + $form.attr('id'));
    return this;
};

$.validator.defaultNormalizeRules = $.validator.normalizeRules;
$.validator.normalizeRules = function (rules, element) {
    rules = $.validator.defaultNormalizeRules(rules, element);
    // clean number parameters
    $.each(['min', 'max'], function () {
        if (rules[this]) {
            rules[this] = Number(rules[this]);
        }
    });
    $.each(['range'], function () {
        if (rules[this]) {
            if (rules[this].length) {
                rules[this][0] = Number(rules[this][0]);
                rules[this][1] = Number(rules[this][1]);
            } else {
                rules[this] = [Number(rules['min']), Number(rules['max'])];
            }
        }
    });
    return rules;
};

$.extend($.validator.methods, {
    number: function (value, element) {
        return this.optional(element) || value.length > 0;
    },
    url: function (value, element) {
        return this.optional(element) || urlRegex({ exact: true, strict: false }).test(value);
    },
    datetimerangevalidation: function (value, element) {
        if (this.optional(element)) {
            return true;
        }

        const min = element.getAttribute('data-val-min');
        const max = element.getAttribute('data-val-max');

        const startDate = utc(min).toDate();
        const endDate = utc(max).toDate();

        const enteredDate = utc(value).toDate() || utc(value, 'L').toDate();

        if (isNaN(enteredDate)) {
            return false;
        }

        return startDate <= enteredDate && enteredDate <= endDate;
    }
});

$.extend($.validator.defaults, {
    highlight: function (element, errorClass, validClass) {
        let target;
        if (element.type === 'radio') {
            target = this.findByName(element.name);
        } else {
            target = $(element);
        }
        if (!target.hasClass(errorClass)) {
            target.addClass(errorClass).removeClass(validClass);
        }
    },
    unhighlight: function (element, errorClass, validClass) {
        let target;
        if (element.type === 'radio') {
            target = this.findByName(element.name);
        } else {
            target = $(element);
        }
        if (target.hasClass(errorClass) && !target.is('input[type="date"]:focus')) {
            target.removeClass(errorClass).addClass(validClass);
        }
    }
});

$.validator.unobtrusive.adapters.add(
    'datetimerangevalidation',
    function (options) {
        options.rules['datetimerangevalidation'] = {};
        options.messages['datetimerangevalidation'] = options.message;
    }
);
$.validator.unobtrusive.adapters.addBool('iban');
$.validator.unobtrusive.adapters.addBool('bic');


$.validator.unobtrusive.adapters.add('checkedmandatory', function (options) {
    if (options.element.tagName.toUpperCase() === 'INPUT' && options.element.type.toUpperCase() === 'CHECKBOX') {
        options.rules['required'] = true;
        if (options.message) {
            options.messages['required'] = options.message;
        }
    }
});
$.validator.unobtrusive.adapters.addBool('checkedmandatory', 'required');
