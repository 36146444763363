import * as ko from 'knockout';
import {
    template,
} from 'lodash-es';
import CatererModel from './CatererModel';
import PictureModel from '../../Shared/Picture/PictureModel';

export default class ViewModel {
    constructor(repository, options, caterer) {
        this.repository = repository;
        this.options = options;
        this.mapping = {
            pictures: {
                create: configuration => new PictureModel(configuration.data, this.mapping),
            },
        };

        this.data = {
            caterer: null,
        };
        this.currentRoomPlanId = ko.observable();
        this.isTermsAccepted = ko.observable(false);

        this.viewPageOnSeatingPlanUrlTemplate = template(this.options.viewPageOnSeatingPlanUrl);
        this.viewPageOnSeatingPlanUrl = ko.pureComputed(() => {
            let viewPageOnSeatingPlanUrl = null;

            if (this.data.caterer && this.data.caterer.data.id()) {
                viewPageOnSeatingPlanUrl = this.viewPageOnSeatingPlanUrlTemplate({
                    id: this.data.caterer.data.id(),
                });
            }

            return viewPageOnSeatingPlanUrl;
        });
        
        this.init(caterer);
    }

    plainTextToHtml(text) {
        if (text !== undefined && text !== null) {
            return text.replace(/\r\n/g, '<br />').replace(/\n/g, '<br />').replace(/\r/g, '<br />');
        }
        return text;
    }

    init(caterer) {
        if (this.data.caterer) {
            this.data.caterer.init(caterer);
        } else {
            this.data.caterer = new CatererModel(caterer, this.mapping, this.repository, this.options);
        }
    }
}