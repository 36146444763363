import * as $ from 'jquery';

export class PageLoaderService {
    public static update(value: number) {
        const pageLoaderElt = $('.page-progress-loader');
        pageLoaderElt.show();
        if (value >= 100) {
            this.end(pageLoaderElt);
        } else {
            pageLoaderElt.width(value + '%');
        }
    }

    private static end(pageLoaderElt: any) {
        pageLoaderElt.on('transitionend', this.transitionEnd);
        pageLoaderElt.css('transition-duration', '500ms');
        pageLoaderElt.width('100%');
    }
    
    private static transitionEnd() {
        const pageLoaderElt = $('.page-progress-loader');
        pageLoaderElt.off('transitionend', this.transitionEnd);
        pageLoaderElt.hide();
        pageLoaderElt.width('0%');
        pageLoaderElt.css('transition-duration', '');
    }
}
