import * as ko from 'knockout';
import $ from 'jquery';

ko.bindingHandlers.selectedText = {
    init: function (element, valueAccessor) {
        const value = valueAccessor();

        $('option', element).filter(function (index, el) {
 return $(el).text() === value;
}).prop('selected', 'selected');

        $(element).change(function () {
            value($('option:selected', this).text());
        });
    },
    update: function (element, valueAccessor) {
        const value = ko.utils.unwrapObservable(valueAccessor());
        $('option', element).filter(function (index, el) {
 return $(el).text() === value;
}).prop('selected', 'selected');
    }
};
