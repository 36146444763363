import imageService from './Image/ImageCustomService';


function scaleImage(inputFile, maxWidth, quality) {
    return imageService.scaleImage(inputFile, maxWidth, quality);
}

export default {
    scaleImage,
};
