import * as ko from 'knockout';

import {
    displayMessage,
} from '../../../Services/MessageService';
import {
    MessageType,
} from '../../../Services/Enums/MessageType';

/* globals window */
export default class ProDomainRoomModel {
    constructor(data, mapping, repository, options) {
        this.mapping = mapping;
        this.repository = repository;
        this.options = options;
        this.isEditing = ko.observable(false);
        this.initialValues = data;
        this.$type = 'ProDomainRoomModel';

        this.init(data);
    }

    revertChanges() {
        ko.mapping.fromJS(this.initialValues, this.mapping, this);
    }

    saveChanges() {
        this.initialValues = ko.mapping.toJS(this);
    }

    modify(proDomainRoom) {
        proDomainRoom.isEditing(true);
    }

    cancel(proDomainRoom) {
        if (proDomainRoom.id()) {
            proDomainRoom.revertChanges();
            proDomainRoom.isEditing(false);
        }
    }

    validateRoomPlan() {
        if (this.id()) {
            this.repository
                .validateRoomPlan(this.id())
                .then((result) => {
                    displayMessage(
                        result.message,
                        null,
                        result.success ? MessageType.SUCCESS : MessageType.ERROR,
                    );

                    if (result.success) {
                        this.roomPlanStatusId(result.data.roomPlanStatusId);
                        this.roomPlanStatus(result.data.roomPlanStatus);
                    }
                });
        }
    }

    createAutomaticOfficialSeatingPlan() {
        let url = this.options.createAutomaticOfficialSeatingPlanUrl;
        if (!url.endsWith('/')) {
            url += '/';
        }
        window.open(url + this.id(), '_blank');
    }

    init(data) {
        ko.mapping.fromJS(data, this.mapping, this);
    }
}
