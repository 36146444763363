function FacebookInit(appId, language) {
    if (language === undefined) {
        language = 'en_US';
    }
    // Load the SDK Asynchronously

    window.fbAsyncInit = function () {
        window.FB.init({
            appId: appId,
            xfbml: true,
            version: 'v3.2'
        });
    };

    (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        const js = d.createElement(s); js.id = id;
        js.src = '//connect.facebook.net/' + language + '/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
}

window.FacebookInit = FacebookInit;
